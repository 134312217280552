import React, { useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { withAuthenticationRequired } from '@auth0/auth0-react';

// Preexisting Feature Toggled Routes, uncomment if needed
//import FeatureToggleRoute from 'components/featureToggleRoute/featureToggleRoute';

import { Auth0State } from 'components/auth';
import ErrorBoundaryFallback from 'components/errorBoundaryFallback/errorBoundaryFallback';
import IdleTimeOutHandler from 'components/idleTimeoutHandler/idleTimeoutHandler';

import { UserInfoProvider } from 'contexts/userInfoContext/userInfoContext';

// Application Route Pathing
import SubscriptionsRoutes from 'containers/subscriptions/subscriptionsRoutes';
import AppDashboardRoutes from 'containers/appDashboard/appDashboardRoutes';
import { ProfileRoutes } from 'containers/profile/profileRoutes';

import PlatformPage from 'pages/platformPage/platformPage';
import ProgressDisplay from 'containers/progressDisplay/progressDisplay';
import SignOut from 'containers/signOut/signOut';
import SignIn from 'components/signin/signin';

import * as EnvDictionary from 'utils/dictionary/env';

import {
  APPLICATION_DASHBOARD_PATH,
  HOME_PATH,
  PROFILE_PATH,
  SIGNOUT_PATH,
  SIGNIN_SUBSCRIPTIONS_PATH,
  SIGNIN_ROOT_PATH,
  UNAUTHORIZED,
  CONTENT_FEEDBACK_PATH,
} from 'utils/dictionary';
import { GlobalStyle } from 'utils/styles/styles';
import { trackException } from 'utils/trackingEvents';

import 'index.css';
import {
  Auth0DataProvider,
  PostLoginRedirector,
  useAuth0Data,
} from 'contexts/auth0Context/auth0Context';
import { Auth0Provider } from '@auth0/auth0-react';
import { UnauthorizedAccess } from 'components/unauthorizedAccess/unauthorizedAccess';
import { ContentFeedback } from 'containers/contentFeedback/contentFeedback';

const isErrorWarning = error => {
  switch (error.name) {
    case 'AuthSdkError':
      return true;
    default:
      return false;
  }
};

const onErrorHandler = (error, info) => {
  const errorType = isErrorWarning(error) ? 'myhealthwise_app_warning' : 'myhealthwise_app_error';

  trackException({
    exception: error,
    name: errorType,
    properties: {
      name: errorType,
      url: window.location.href,
      info,
    },
  });
};

//import { ErrorBoundary, useErrorHandler } from 'react-error-boundary';
// const ErrorComponent = ({ error }) => {
//   useErrorHandler(error);
//   return null;
// };

const AuthorizedApp = () => {
  const { error, isLoading } = Auth0State();

  if (isLoading) {
    return (
      <>
        <GlobalStyle />
        <PlatformPage>
          <ProgressDisplay message="Loading MyHealthwise" />
        </PlatformPage>
      </>
    );
  }

  if (error) {
    // TODO - Convert to user presentable error message & capture error message into logging system
    return <ProgressDisplay message={error.message} />;
  }

  const queryClient = new QueryClient();
  let SecuredAppDashboardRoutes = withAuthenticationRequired(AppDashboardRoutes);
  let SecuredSubscriptionsRoutes = withAuthenticationRequired(SubscriptionsRoutes);
  let SecuredProfileRoutes = withAuthenticationRequired(ProfileRoutes);
  let SecuredUnauthorizedRoute = withAuthenticationRequired(UnauthorizedAccess);
  let SecuredContentFeedbackRoute = withAuthenticationRequired(ContentFeedback);

  return (
    <>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <UserInfoProvider>
          <IdleTimeOutHandler />
          <Routes>
            <Route exact path={SIGNIN_ROOT_PATH} element={<SignIn />} />
            <Route
              path={HOME_PATH}
              exact
              element={<Navigate replace to={APPLICATION_DASHBOARD_PATH} />}
            />
            <Route path={APPLICATION_DASHBOARD_PATH} element={<SecuredAppDashboardRoutes />} />
            <Route path={SIGNIN_SUBSCRIPTIONS_PATH} element={<SecuredSubscriptionsRoutes />} />
            <Route path={CONTENT_FEEDBACK_PATH} element={<SecuredContentFeedbackRoute />} />
            <Route path={PROFILE_PATH} element={<SecuredProfileRoutes />} />
            <Route path={UNAUTHORIZED} element={<SecuredUnauthorizedRoute />} />
            <Route path={`/:basename?/${SIGNOUT_PATH}`} element={<SignOut />} />
            <Route path="*" element={<SecuredAppDashboardRoutes />} />
          </Routes>
        </UserInfoProvider>
      </QueryClientProvider>
    </>
  );
};

const filterRoutes = [
  APPLICATION_DASHBOARD_PATH.replace('/', ''),
  SIGNOUT_PATH.replace('/', ''),
  PROFILE_PATH.replace('/', ''),
  SIGNIN_ROOT_PATH.replace('/', ''),
  CONTENT_FEEDBACK_PATH.replace('/', ''),
  'unauthorized',
];

const App = () => {
  const auth0Data = useAuth0Data();
  const [organizationAlias, setOrganizationAlias] = useState();

  if (!auth0Data || auth0Data.isLoading) return <ProgressDisplay message="Loading MyHealthwise" />;

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={onErrorHandler}>
      <Auth0Provider {...auth0Data.auth0Config}>
        <BrowserRouter basename={organizationAlias}>
          <PostLoginRedirector setOrgAlias={setOrganizationAlias} filterRoutes={filterRoutes}>
            <AuthorizedApp />
          </PostLoginRedirector>
        </BrowserRouter>
      </Auth0Provider>
    </ErrorBoundary>
  );
};

const Auth0App = () => {
  return (
    <Auth0DataProvider
      domain={EnvDictionary.REACT_APP_AUTH0_DOMAIN}
      clientId={EnvDictionary.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={EnvDictionary.REACT_APP_AUTH0_AUDIENCE}
      scope={EnvDictionary.REACT_APP_AUTH0_SCOPE}
      filterRoutes={filterRoutes}
      myHealthwiseBaseUrl={EnvDictionary.REACT_APP_MYHEALTHWISE_API_BASE_URL}
    >
      <App />
    </Auth0DataProvider>
  );
};

export default Auth0App;
