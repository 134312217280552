import { useProfile } from 'utils/hooks/useProfile/useProfile';
import { useState, useEffect } from 'react';
import { LOG_SERVICE_REQUEST_KEY } from 'utils/dictionary';

export const useShowLogRequest = () => {
  const [showLogRequest, setShowRequest] = useState(false);
  const { data: profileResponse } = useProfile();
  const userProfile = profileResponse?.data;

  const showLogRequestFromSession = sessionStorage.getItem(LOG_SERVICE_REQUEST_KEY) === 'true';

  useEffect(() => {
    if (userProfile) {
      if (
        userProfile.logService !== showLogRequest ||
        showLogRequestFromSession !== userProfile.logService
      ) {
        setShowRequest(userProfile.logService);
        sessionStorage.setItem(
          LOG_SERVICE_REQUEST_KEY,
          userProfile.logService?.toString() ?? false.toString(),
        );
      }
    }
  }, [userProfile, showLogRequest, showLogRequestFromSession]);

  return { showLogRequest };
};