import styled from 'styled-components';
import { Link as MaterialLink } from '@mui/material';

import { Tooltip } from '@mui/material';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL, SCREEN_READER_TOOLTIP_TEXT } from 'utils/dictionary';

const StyledFooter = styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  background: ${props => props.theme.colors.darkestGrey};
  height: auto;
`;

const StyledCopyright = styled.p`
  font-size: ${props => props.theme.typeScale[1]};
  color: ${props => props.theme.colors.white};
  font-weight: regular;
  text-decoration: none;
  white-space: pre-wrap;
  margin-left: 37px;
  display: inline-block;
`;

const StyleLink = styled(MaterialLink)`
  color: ${props => props.theme.colors.white};
  white-space: nowrap;
  text-decoration: underline;
  padding: 0 8px;
`;

// https://stackoverflow.com/a/72273054 for the crazyiness of the tooltip
const StyledTooltip = styled(props => <Tooltip classes={{ popper: props.className }} {...props} />)`
  & .MuiTooltip-arrow {
    color: ${props => props.theme.colors.primaryTextOnDark};
    background-color: ${props => props.theme.colors.primaryText};
  }
  & .MuiTooltip-tooltip {
    font-size: ${props => props.theme.typeScale[1]};
    color: ${props => props.theme.colors.primaryTextOnDark};
    background-color: ${props => props.theme.colors.secondaryText};
    width: '200px';
    padding: '6px';
  }
`;

const copyrightYear = props => {
  var currentyear = new Date().getFullYear();
  return ' ' + currentyear;
};

export const Footer = () => {
  return (
    <StyledFooter>
      <StyledCopyright>
          ©{copyrightYear()} Ignite Healthwise, LLC&nbsp;&nbsp;|
        <StyledTooltip title={SCREEN_READER_TOOLTIP_TEXT} arrow>
          <StyleLink href={PRIVACY_POLICY_URL} target="_blank" rel="noopener">
            Privacy Notice
          </StyleLink>
        </StyledTooltip>
        •
        <StyledTooltip title={SCREEN_READER_TOOLTIP_TEXT} arrow>
          <StyleLink href={TERMS_OF_USE_URL} target="_blank" rel="noopener">
            Terms of Use
          </StyleLink>
        </StyledTooltip>
      </StyledCopyright>
    </StyledFooter>
  );
};

export default Footer;
