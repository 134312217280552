import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IconButton, } from '@mui/material';
import { ArrowBackIcon } from 'components/icons/icons';
import styled from 'styled-components';
import { deviceHeight } from './screenSizes';

import { Auth0Jwt } from 'components/auth';
import { APPLICATION_DASHBOARD_PATH, CONTENT_FEEDBACK_URL } from 'utils/dictionary';

import PlatformPage from 'pages/platformPage/platformPage';
import { useShowContentFeedback } from 'utils/hooks/displayComponent/useShowContentFeedback';

const StyledSection = styled.section`
  position: absolute;
  overflow: hidden;
  width: 77vw;

  @media ${deviceHeight.xs} {
    height: 50vh;
  }

  @media ${deviceHeight.sm} {
    height: 60vh;
  }

  @media ${deviceHeight.md} {
    height: 65vh;
  }

  @media ${deviceHeight.lg} {
    height: 70vh;
  }
`;

const StyledIframe = styled.iframe`
  width: 82vw;
  height: 100%;
  border: none;
`;

const StyledPrevPageButton = styled(IconButton)`
  font-size: ${props => props.theme.typeScale[2]};
  color: ${props => props.theme.colors.primary};
  margin-bottom: 10px;
  border-radius: 5px;
  &:hover {
    background-color: ${props => props.theme.colors.lighterGrey};
  }
`;

const StyledDiv = styled.div`
  padding-top: 20px;
`;

const StyledArrowBackIcon = styled(ArrowBackIcon)`
  color: ${props => props.theme.colors.darkPrimary};
  margin-right: 5px;
`;

const generateContentFeedbackUrl = () => {
  let auth0Jwt = Auth0Jwt();
  let contentFeedbackUrl = `${CONTENT_FEEDBACK_URL}#${auth0Jwt}`;
  return contentFeedbackUrl;
};

export const ContentFeedback = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const contentFeedbackUrl = generateContentFeedbackUrl();
  const { showContentFeedback } = useShowContentFeedback();
  const [renderIframe, setRenderIframe] = useState();

  const goToMyHealthwise = () => {
    navigate({
      pathname: APPLICATION_DASHBOARD_PATH,
      search: search
    });
  };

  useEffect(() => {
    // Only render the iframe if permissed
    setRenderIframe(showContentFeedback === true);
  }, [renderIframe, showContentFeedback]);

  return (
    <PlatformPage>
      <StyledDiv>
        <StyledPrevPageButton onClick={goToMyHealthwise}>
          <StyledArrowBackIcon />
          Back to MyHealthwise
        </StyledPrevPageButton>
      </StyledDiv>

      {renderIframe &&
        <StyledSection>
          <StyledIframe src={contentFeedbackUrl} allowTransparency="true" />
        </StyledSection>
      }
    </PlatformPage>
  );
};
