import { useState, useEffect, useContext } from 'react';
import { CONTENT_FEEDBACK_REQUEST_KEY } from 'utils/dictionary';
import { useManagementSubscriptionSettings } from '../useManagementSubscriptionSettings/useManagementSubscriptionSettings';
import { UserInfoContext } from 'contexts/userInfoContext/userInfoContext';

export const useShowContentFeedback = () => {
  const [showContentFeedback, setShowContentFeedback] = useState();
  const userInfo = useContext(UserInfoContext);
  const userProfile = userInfo?.data?.data;
  const { subscriptionSettings } = useManagementSubscriptionSettings();
  const showContentFeedbackFromSession =
    sessionStorage.getItem(CONTENT_FEEDBACK_REQUEST_KEY) === 'true';

  useEffect(() => {
    if (userProfile && subscriptionSettings && subscriptionSettings.length) {
      if (
        userProfile.contentFeedback !== showContentFeedback ||
        showContentFeedbackFromSession !== userProfile.contentFeedback
      ) {
        const enabledForManagementSubscription = subscriptionSettings
          .some(opt => (opt.name === 'Content Feedback' && opt.option.optionValue === 'true'));

        setShowContentFeedback((userProfile.contentFeedback && enabledForManagementSubscription));
        sessionStorage.setItem(
          CONTENT_FEEDBACK_REQUEST_KEY,
          userProfile.contentFeedback?.toString() ?? false.toString(),
        );
      }
    } else {
      const enabledForManagementSubscription = subscriptionSettings
          .some(opt => (opt.name === 'Content Feedback' && opt.option.optionValue === 'true'));
      setShowContentFeedback((showContentFeedbackFromSession && enabledForManagementSubscription));
    }
  }, [userProfile, subscriptionSettings]);

  return { showContentFeedback };
};