import * as EnvDictionary from 'utils/dictionary/env';
/* STORAGE SETTINGS */
export const PROXY_STORAGE_KEY = 'hw-myhealthwise-proxy-settings';
export const LOG_SERVICE_REQUEST_KEY = 'log-service-request';
export const CONTENT_FEEDBACK_REQUEST_KEY = 'content-feedback-request';

/* SERVICE CALL ERROR MESSAGE */
export const ERROR_MESSAGE_UNAUTHORIZED = 'Unauthorized';
export const ERROR_MESSAGE_INTERNAL_SERVER_ERROR = 'Internal Server Error';
export const ERROR_MESSAGE_NETWORK_ERROR = 'Network Error';

/* USER-FACING STRINGS (TODO: SUPPORT LOCALIZATION) */
export const PRODUCT_NAME = 'MyHealthwise';
export const ERROR_MESSAGE_PREAMBLE = `We're sorry, ${PRODUCT_NAME} has encountered an error`;
export const ERROR_MESSAGE_SUPPORT = 'Please try again in a moment, or contact your administrator.';
export const HEALTHWISE_LOGO_LINK_ARIA = 'My Healthwise Landing Page';

export const HEADER_ACCOUNT_MENU_ARIA = 'my account';

export const APPLICATIONS_ERROR_MESSAGE =
  'No applications found. If you believe this is a mistake, review your profile and contact your administrator.';
export const SUBSCRIPTIONS_ERROR_MESSAGE =
  'No subscriptions found. If you believe this is a mistake, review your profile and contact your administrator.';
export const SUBSCRIPTIONS_ERROR_USE_DIFFERENT_ACCOUNT = 'Use a different account';
export const SUBSCRIPTIONS_TOOLTIP_EXPLANATION =
  'Subscriptions are containers used to manage and isolate your Healthwise resources in different environments like production or test.';

/* USER MENU */
export const USER_MENU_SIGN_OUT_TEXT = 'Sign Out';
export const USER_MENU_GET_HELP_TEXT = 'Get Help';
export const USER_MENU_MY_PROFILE_BUTTON_TEXT = 'My Profile';
export const USER_MENU_LOG_SERVICE_REQUEST_TEXT = 'Log Service Request';
export const USER_MENU_SUBMIT_CONTENT_FEEDBACK_TEXT = 'Submit Content Feedback';
export const USER_MENU_MYHEALTHWISE_BUTTON_TEXT = 'MyHealthwise';
export const HELP_URL = EnvDictionary.REACT_APP_HELP_URL;
export const LOG_SERVICE_REQUEST_URL = EnvDictionary.REACT_APP_SERVICE_REQUEST_URL;
export const CONTENT_FEEDBACK_URL = EnvDictionary.REACT_APP_CONTENT_FEEDBACK_URL;

/* PROFILE PAGE */
export const ROLES_AND_PERMISSIONS_TOOLTIP_EXPLANATION =
  'Roles and permissions are grouped by subscriptions and applications that your company licenses. Subscriptions are containers used to manage and isolate your Healthwise resources in different environments like production or test. If you need further permissions, please reach out to your organization administrator(s).';
export const USER_PROFILE_ROLES_TITLE = 'My Roles and Permissions';
export const SUBSCRIPTION_NAME = 'Subscription: ';
export const BACK_TO_PREVIOUS_PAGE = 'Back to Previous Page';
export const MY_PROFILE = 'My Profile';
export const MY_PROFILE_TEXT = 'Edit your personal information or view your roles and permissions.';

/* CHANGE PASSWORD */
export const CANCEL_BUTTON_TEXT = 'Cancel';

/* API */
export const API_VERSION_QUERY_KEY = 'api-version';

/* Needed for the CSC 'AppCard' */
export const CLIENT_SUPPORT_PATH = `${process.env.PUBLIC_URL + '/assets/client-support.svg'}`;
export const CLIENT_SUPPORT_URL = `${EnvDictionary.REACT_APP_CLIENT_SUPPORT_URL}`;

/* Needed for the Log Service Request 'AppCard' */
export const SERVICE_REQUEST_PATH = `${process.env.PUBLIC_URL + '/assets/service-request.svg'}`;
export const SERVICE_REQUEST_URL = `${EnvDictionary.REACT_APP_SERVICE_REQUEST_URL}`;

/* Privacy Policy URL */
export const PRIVACY_POLICY_URL = 'https://www.healthwise.org/specialpages/legal/privacy.aspx';

/* Terms of Use Url */
export const TERMS_OF_USE_URL = 'https://www.healthwise.org/specialpages/legal/terms.aspx';

export const SCREEN_READER_TOOLTIP_TEXT =
  'You are leaving this website for information purposes only.';

export const SCREEN_READER_CSC_TOOLTIP = `You are opening the Client Success Center in a new tab. 
Additional login credentials are required to access the Client Success Center. If you are missing 
login details and believe you should have access, please contact your Healthwise Account Manager 
to get set up.`;

export const SCREEN_READER_LSR_TOOLTIP = 'You are opening Log Service Request in a new tab.';

export const SCREEN_READER_CFB_TOOLTIP = 'You are opening Submit Content Feedback in a new tab.';

/* COMBINE OTHER DICTIONARIES */
export * from 'utils/dictionary/routes';
export * from 'utils/dictionary/featureToggles';
export * from 'utils/dictionary/env';
