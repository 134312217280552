import React from 'react';
import PropTypes from 'prop-types';

import { Auth0State } from 'components/auth';
import ProgressDisplay from 'containers/progressDisplay/progressDisplay';
import PlatformPage from 'pages/platformPage/platformPage';

import { REACT_APP_MYHEALTHWISE_API_BASE_URL } from 'utils/dictionary/env';
import { PROXY_STORAGE_KEY, LOG_SERVICE_REQUEST_KEY, CONTENT_FEEDBACK_REQUEST_KEY } from 'utils/dictionary';
import { usePostLoginContext  } from 'contexts/auth0Context/auth0Context';

const signOut = async (auth0State, organizationAlias) => {
    const { logout } = auth0State;

    /**
     * The query string parameter is used here because if we used a route
     * parameter, Auth0 would require a redirect for each and every organization
     * alias. This way we can pass the orgaizationAlias through.
     * 
     * The parameter organizationAlias is always non-null or empty, as this method
     * does not get called from the parent component if it ever is.
     */
    const returnTo = `${REACT_APP_MYHEALTHWISE_API_BASE_URL}?orgAlias=${organizationAlias}`;
    
    // https://auth0.github.io/auth0-react/interfaces/Auth0ContextInterface.html#logout
    await logout({
        logoutParams: {
            returnTo: returnTo
        }
    });
};

const SignOut = ({ minimumDisplayTimeMs }) => {
    const authState = Auth0State();
    const { organizationAlias } = usePostLoginContext() ?? {};

    React.useEffect(() => {
        sessionStorage.removeItem('features');
        sessionStorage.removeItem(PROXY_STORAGE_KEY);
        sessionStorage.removeItem(LOG_SERVICE_REQUEST_KEY);
        sessionStorage.removeItem(CONTENT_FEEDBACK_REQUEST_KEY);

        if (authState && organizationAlias) {
            const timer = setTimeout(() => signOut(authState, organizationAlias), minimumDisplayTimeMs);
            return () => clearTimeout(timer);
        }
    }, [authState, organizationAlias, minimumDisplayTimeMs]);

    return (
        <PlatformPage>
            <ProgressDisplay message="Signing Out" />
        </PlatformPage>
    );
};

SignOut.propTypes = {
  minimumDisplayTimeMs: PropTypes.number,
};

SignOut.defaultProps = {
  minimumDisplayTimeMs: 2500,
};

export default SignOut;
